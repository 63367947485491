<template>
  <slot></slot>
  <Toaster />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useConfigStore } from '@/stores/config.store'
import { Toaster } from '@/components/ui/toast'

const configStore = useConfigStore()

onMounted(() => {
  configStore.applyThemeToHtml()
})
</script>
