import { defineStore } from 'pinia'
import { http } from '@/services/http.service'
import { useAuthStore } from '@/stores/auth.store'

export const RADII = [0, 0.25, 0.5, 0.75, 1]

export const useConfigStore = defineStore('config', {
  persist: true,
  state: () => ({
    baseColor: 'neutral',
    radius: 0.5 // Valor por defecto
  }),
  actions: {
    async setBaseColor(color: string, toast: Function) {
      this.baseColor = color
      await this.updateTheme(toast)
    },
    setRadius(newRadius: number, toast: Function) {
      this.radius = newRadius
      this.updateTheme(toast)
    },
    async updateTheme(toast: Function) {
      this.applyThemeToHtml()
      const authStore = useAuthStore()

      if (authStore.user) {
        try {
          await http.patch(`/users/update_manual/${authStore.user.id}`, {
            user: {
              base_color: this.baseColor,
              radius: this.radius
            }
          })
          toast({
            title: 'Tema actualizado',
            description: `Se ha cambiado el tema.`,
          })
        } catch (error) {
          toast({
            title: 'Error al cambiar el tema',
            description: 'Por favor, inténtalo de nuevo más tarde.',
          })
        }
      }
    },
    applyThemeToHtml() {
      const htmlElement = document.documentElement
      // Limpiar clases de tema anteriores
      htmlElement.classList.forEach(className => {
        if (className.startsWith('theme-')) {
          htmlElement.classList.remove(className)
        }
      })

      // Añadir clase de tema base
      htmlElement.classList.add(`theme-${this.baseColor}`)

      // Aplicar el radio
      htmlElement.style.setProperty('--radius', `${this.radius}rem`)
    }
  }
})
